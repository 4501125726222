import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { API } from 'aws-amplify';
import CDialog from '../CDialog';
import { Undo } from '@material-ui/icons';
import { isNil } from 'lodash';

function CircularLoading() {
  return <CircularProgress color="secondary" size={18} style={{ marginLeft: 3 }} />
}

function ButtonAction({ label, isSubmitting, disabled, onClick, title }) {
  return (
    <Button size="small" variant="outlined" disabled={disabled} color="secondary" onClick={onClick} title={title}>
      {label} {isSubmitting ? <CircularLoading /> : <Undo style={{ marginLeft: 3, fontSize: 18 }} />}
    </Button>
  )
}

class ReverseDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      attribute: ""
    };
  }

  async reverseOrder(attribute) {
    const { order, currUser, onSuccess } = this.props;

    try {
      this.setState({ isSubmitting: true, attribute });
      await API.post("v2", `marketplace/orders/reverse`, {
        headers: { email: currUser.email },
        body: { orderId: order.id, attribute }
      });
      this.setState({ isSubmitting: false });
      onSuccess(order.id, attribute);
    } catch (error) {
      this.setState({ isSubmitting: false });
      CDialog.error("Error!", "Failed to undo the action.");
    }
  }

  render() {
    const { isOpen, order, handleClose } = this.props;
    const { isSubmitting, attribute } = this.state;

    if (isNil(order)) {
      return <></>;
    }

    return (
      <Dialog
        fullWidth={true}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reverse Order Action</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>{order.trackingNumber}</Typography>
          <Typography gutterBottom>{order.name}</Typography>
          <Typography gutterBottom>{order.email}</Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            {order.description}
          </Typography>
          <Grid container justify="center" spacing={8}>
            <Grid item>
              <ButtonAction
                label="Reverse Sent"
                isSubmitting={isSubmitting && attribute === "isSent"}
                disabled={isSubmitting || !order.isSent}
                onClick={() => this.reverseOrder("isSent")}
                title={order.isSent ? "Undo Sent Button" : "Have not sent yet"}
              />
            </Grid>
            <Grid item>
              <ButtonAction
                label="Reverse Paid"
                isSubmitting={isSubmitting && attribute === "isPaid"}
                disabled={isSubmitting || !order.isPaid}
                onClick={() => this.reverseOrder("isPaid")}
                title={order.isPaid ? "Undo Paid Button" : "Have not paid yet"}
              />
            </Grid>
            <Grid item>
              <ButtonAction
                label="Reverse Refunded"
                isSubmitting={isSubmitting && attribute === "isRefunded"}
                disabled={isSubmitting || !order.isRefunded}
                onClick={() => this.reverseOrder("isRefunded")}
                title={order.isRefunded ? "Undo Refunded Button" : "Have not refunded yet"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.isSubmitting}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReverseDialog;
