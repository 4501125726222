import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AppBar, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@material-ui/core';
import { API } from "aws-amplify";
import CDialog from '../components/CDialog';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import validator from "validator";
import { defaultTo, get, has, isEmpty, isNil } from 'lodash';
import { isSuper } from '../libs/rbac';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class UserSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      isLoadingAdmin: false,
      isLoadingSupport: false,
      isSaving: false,
      isUpdating: false,
      isDeleting: false,
      isSubmitted: false,
      openDialog: false,
      openAddDialog: false,
      openEditDialog: false,
      selectedUser: null,
      editedUser: {
        username: "",
        name: "",
        email: "",
        old_email: "",
        phone_number: "",
        group: '',
        old_group: '',
      },
      resetUser: {
        password: "",
        confirm_password: ""
      },
      newUser: {
        name: "",
        email: "",
        phone_number: "",
        group: '',
        password: "",
        confirm_password: ""
      },
      tab: 0
    };
  }

  findKey(attributes, name) {
    const attribute = attributes.find(attribute => attribute.Name === name);
    if (attribute) {
      return attribute.Value
    }

    return null
  }

  isEmailExist(email) {
    const user = this.state.users.find(user => this.findKey(user.Attributes, 'email') === email);
    return !isEmpty(user);
  }

  validateInput(input, isEdited = false) {
    const { currUser } = this.props;
    const { name, email, phone_number, password, confirm_password, group } = input;
    return {
      ...(!isSuper(currUser.userGroup) && !isNil(name) && validator.isEmpty(name) && { name: "Name is required" }),
      ...(!isNil(email) && validator.isEmpty(email) && { email: "Email is required" }),
      ...(!isNil(email) && !validator.isEmpty(email) && !validator.isEmail(email) && { email: "Please enter a valid email address" }),
      ...(!isNil(email) && !validator.isEmpty(email) && validator.isEmail(email) && this.isEmailExist(email) && !isEdited && { email: "This email address is already registered" }),
      ...(!isSuper(currUser.userGroup) && !isNil(phone_number) && validator.isEmpty(phone_number) && { phone_number: "Phone number is required" }),
      ...(!isNil(phone_number) && !validator.isEmpty(phone_number) && !validator.isMobilePhone(phone_number, 'any', { strictMode: true }) && { phone_number: "Please enter a valid phone number including country code" }),
      ...(!isNil(group) && validator.isEmpty(group) && { group: "Group is required" }),
      ...(!isNil(password) && validator.isEmpty(password) && { password: "Password is required" }),
      ...(!isNil(password) && !validator.isEmpty(password) && password.length < 6 && { password: "Password must be at least 6 characters" }),
      ...(!isNil(confirm_password) && validator.isEmpty(confirm_password) && { confirm_password: "Confirm password is required" }),
      ...(!isNil(confirm_password) && !validator.isEmpty(confirm_password) && !validator.equals(password, confirm_password) && { confirm_password: "Passwords do not match" }),
    };
  }

  loadData = () => {
    const { currUser } = this.props;
    this.setState({ isLoadingSupport: true });
    API.get("v2", "marketplace/users/by/group?group=Supports&limit=20")
      .then(response => {
        const newUsers = this.state.users.filter(user => user.group !== "Supports");
        this.setState({
          users: [...newUsers, ...response.users],
          isLoadingSupport: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isLoadingSupport: false });
      });

    if (isSuper(currUser.userGroup)) {
      this.setState({ isLoadingAdmin: true });
      API.get("v2", "marketplace/users/by/group?group=Admins&limit=20")
        .then(response => {
          const newUsers = this.state.users.filter(user => user.group !== "Admins");
          this.setState({
            users: [...newUsers, ...response.users],
            isLoadingAdmin: false
          });
        }).catch(error => {
          CDialog.error("Error", "Internal Server Error");
          this.setState({ isLoadingAdmin: false });
        });
    }
  }

  resetPassword = () => {
    const { selectedUser, resetUser } = this.state;
    const { currUser } = this.props;
    this.setState({ isSubmitted: true });

    if (!isEmpty(this.validateInput(resetUser))) {
      return;
    }
    this.setState({ isUpdating: true });
    API.post("v2", "marketplace/users/set-password", {
      headers: { email: currUser.email },
      body: {
        username: get(selectedUser, 'Username'),
        email: this.findKey(selectedUser.Attributes, 'email'),
        password: resetUser.password
      }
    })
      .then(response => {
        this.setState({ isUpdating: false });
        this.closeDialog();
        CDialog.success("Success", "Password successfully changed");
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isUpdating: false });
      });
  }

  addUser = () => {
    const { currUser } = this.props;
    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput(this.state.newUser))) {
      return;
    }
    this.setState({ isSaving: true });
    API.post("v2", "marketplace/users/create", {
      headers: { email: currUser.email },
      body: this.state.newUser
    })
      .then(response => {
        this.setState({
          isSaving: false,
          isSubmitted: false,
          users: [...this.state.users, response.user]
        });
        this.closeAddDialog();
        CDialog.success("Success", "User has been saved");
      }).catch(error => {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 403) {
            CDialog.warning("Error", data.message);
          } else {
            CDialog.error("Error", "Internal Server Error");
          }
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
        this.setState({ isSaving: false });
      });
  }

  editUser = () => {
    const { currUser } = this.props;
    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput(this.state.editedUser, true))) {
      return;
    }
    this.setState({ isUpdating: true });

    API.post("v2", "marketplace/users/update", {
      headers: { email: currUser.email },
      body: this.state.editedUser
    })
      .then(response => {
        const newUsers = this.state.users.map(user => {
          if (user.Username === this.state.editedUser.username) {
            return response.user;
          }
          return user;
        });
        this.setState({
          isUpdating: false,
          isSubmitted: false,
          users: newUsers
        });
        this.closeEditDialog();
        CDialog.success("Success", "User has been updated");
      }).catch(error => {
        this.setState({ isUpdating: false });
        if (error.response) {
          const { data } = error.response;
          CDialog.warning("Error", data.message);
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
      });
  }

  removeGroup = (user) => {
    const { currUser } = this.props;
    CDialog.loading(true);
    API.post("v2", "marketplace/users/remove-group", {
      headers: { email: currUser.email },
      body: {
        username: user.Username,
        email: this.findKey(user.Attributes, 'email'),
        group: user.group
      }
    })
      .then(response => {
        CDialog.loading(false);
        CDialog.success("Success", "User successfully removed from support group");
        const newUsers = this.state.users.filter(item => item.Username !== user.Username);
        this.setState({ users: newUsers });
      }).catch(error => {
        CDialog.loading(false);
        CDialog.error("Error", "Internal Server Error");
      });
  }

  componentDidMount() {
    this.loadData();
  }

  openDialog(user) {
    this.setState({
      selectedUser: user,
      openDialog: true
    });
  }

  openEditDialog(user) {
    this.setState({
      editedUser: {
        username: user.Username,
        name: defaultTo(this.findKey(user.Attributes, 'name'), ""),
        email: this.findKey(user.Attributes, 'email'),
        old_email: this.findKey(user.Attributes, 'email'),
        phone_number: defaultTo(this.findKey(user.Attributes, 'phone_number'), ""),
        group: user.group,
        old_group: user.group
      },
      openEditDialog: true
    });
  }

  closeDialog() {
    this.setState({
      selectedUser: null,
      resetUser: {
        password: "",
        confirm_password: ""
      },
      openDialog: false,
      isSubmitted: false,
    });
  }

  closeAddDialog() {
    this.setState({
      newUser: {
        name: "",
        email: "",
        phone_number: "",
        group: '',
        password: "",
        confirm_password: ""
      },
      openAddDialog: false,
      isSubmitted: false,
    });
  }

  closeEditDialog() {
    this.setState({
      editedUser: {
        username: "",
        name: "",
        email: "",
        old_email: "",
        phone_number: "",
        group: '',
        old_group: '',
      },
      openEditDialog: false,
      isSubmitted: false,
    });
  }

  handleDelete(user) {
    const name = this.findKey(user.Attributes, 'name');
    const email = this.findKey(user.Attributes, 'email');
    CDialog.confirm("Are you sure?", `Remove ${name} (${email}) from the ${user.group} group`)
      .then(confirm => {
        if (confirm) {
          this.removeGroup(user);
        }
      })
  }

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
  };

  renderAddDialog() {
    const { currUser } = this.props;
    const { newUser, isSaving, isSubmitted } = this.state;
    const validated = this.validateInput(newUser);
    return (
      <Dialog open={this.state.openAddDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Add new user</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={newUser.name}
            onChange={e => this.setState({ newUser: { ...newUser, name: e.target.value } })}
            error={!isSuper(currUser.userGroup) && isSubmitted && has(validated, 'name')}
            helperText={!isSuper(currUser.userGroup) && isSubmitted && get(validated, 'name')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="email"
            value={newUser.email}
            onChange={e => this.setState({ newUser: { ...newUser, email: e.target.value.toLowerCase() } })}
            error={isSubmitted && has(validated, 'email')}
            helperText={isSubmitted && get(validated, 'email')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="phone_number"
            label="Phone Number"
            type="text"
            value={newUser.phone_number}
            onChange={e => this.setState({ newUser: { ...newUser, phone_number: e.target.value } })}
            error={isSubmitted && has(validated, 'phone_number')}
            helperText={isSubmitted && get(validated, 'phone_number')}
            fullWidth
          />
          <FormControl fullWidth error={isSubmitted && has(validated, 'group')}>
            <InputLabel shrink htmlFor="group">
              Group
            </InputLabel>
            <Select
              value={newUser.group}
              onChange={e => this.setState({ newUser: { ...newUser, group: e.target.value } })}
              inputProps={{
                name: 'group',
                id: 'group',
              }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {isSuper(currUser.userGroup) && <MenuItem value="Admins">Admin</MenuItem>}
              <MenuItem value="Supports">Support</MenuItem>
            </Select>
            {isSubmitted && has(validated, 'group') && <FormHelperText>{get(validated, 'group')}</FormHelperText>}
          </FormControl>
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={newUser.password}
            onChange={e => this.setState({ newUser: { ...newUser, password: e.target.value } })}
            error={isSubmitted && has(validated, 'password')}
            helperText={isSubmitted && get(validated, 'password')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="confirm-password"
            label="Confirm Password"
            type="password"
            value={newUser.confirm_password}
            onChange={e => this.setState({ newUser: { ...newUser, confirm_password: e.target.value } })}
            error={isSubmitted && has(validated, 'confirm_password')}
            helperText={isSubmitted && get(validated, 'confirm_password')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeAddDialog()} color="primary" disabled={this.state.isSaving}>
            Cancel
          </Button>
          <Button onClick={() => this.addUser()} color="primary" variant="contained" disabled={isSaving}>
            {this.state.isSaving ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderEditDialog() {
    const { currUser } = this.props;
    const { openEditDialog, editedUser, isUpdating, isSubmitted } = this.state;
    const validated = this.validateInput(editedUser, true);
    return (
      <Dialog open={openEditDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Edit user</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={editedUser.name}
            onChange={e => this.setState({ editedUser: { ...editedUser, name: e.target.value } })}
            error={!isSuper(currUser.userGroup) && isSubmitted && has(validated, 'name')}
            helperText={!isSuper(currUser.userGroup) && isSubmitted && get(validated, 'name')}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            value={editedUser.email}
            onChange={e => this.setState({ editedUser: { ...editedUser, email: e.target.value.toLowerCase() } })}
            error={isSubmitted && has(validated, 'email')}
            helperText={isSubmitted && get(validated, 'email')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="phone_number"
            label="Phone Number"
            type="text"
            value={editedUser.phone_number}
            onChange={e => this.setState({ editedUser: { ...editedUser, phone_number: e.target.value } })}
            error={isSubmitted && has(validated, 'phone_number')}
            helperText={isSubmitted && get(validated, 'phone_number')}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="group">
              Group
            </InputLabel>
            <Select
              value={editedUser.group}
              onChange={e => this.setState({ editedUser: { ...editedUser, group: e.target.value } })}
              inputProps={{
                name: 'group',
                id: 'group',
              }}
            >
              {isSuper(currUser.userGroup) && <MenuItem value="Admins">Admin</MenuItem>}
              <MenuItem value="Supports">Support</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeEditDialog()} color="primary" disabled={isUpdating}>
            Cancel
          </Button>
          <Button onClick={() => this.editUser()} color="primary" variant="contained" disabled={isUpdating}>
            {isUpdating ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderResetDialog() {
    const { openDialog, selectedUser, isUpdating, resetUser, isSubmitted } = this.state;
    const validated = this.validateInput(resetUser);
    return (
      <Dialog open={openDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Reset User Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedUser && (
              <Typography variant="h3">{this.findKey(selectedUser.Attributes, 'name')}</Typography>
            )}
            {selectedUser && (
              <Typography variant="h3">{this.findKey(selectedUser.Attributes, 'email')}</Typography>
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={resetUser.password}
            onChange={e => this.setState({ resetUser: { ...resetUser, password: e.target.value } })}
            error={isSubmitted && has(validated, 'password')}
            helperText={isSubmitted && get(validated, 'password')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="confirm-password"
            label="Confirm Password"
            type="password"
            value={resetUser.confirm_password}
            onChange={e => this.setState({ resetUser: { ...resetUser, confirm_password: e.target.value } })}
            error={isSubmitted && has(validated, 'confirm_password')}
            helperText={isSubmitted && get(validated, 'confirm_password')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeDialog()} color="primary" disabled={isUpdating}>
            Cancel
          </Button>
          <Button onClick={() => this.resetPassword()} color="primary" variant="contained" disabled={isUpdating}>
            {isUpdating ? 'Loading...' : 'Reset'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderTable(group, isLoading) {
    const { classes } = this.props;
    const { users } = this.state;
    const userGroups = users.filter(user => user.group === group);
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell align="center" colSpan="4">
                <Typography variant="subheading" style={{ 'textAlign': 'center' }}>Loading...</Typography>
              </TableCell>
            </TableRow>
          )}
          {userGroups.map(user => (
            <TableRow key={user.Username}>
              <TableCell component="th" scope="row">
                {this.findKey(user.Attributes, 'name')}
              </TableCell>
              <TableCell>
                {this.findKey(user.Attributes, 'email')}
              </TableCell>
              <TableCell>
                {this.findKey(user.Attributes, 'phone_number')}
              </TableCell>
              <TableCell>
                <Button variant="contained" color="primary" size="small" onClick={() => this.openEditDialog(user)}>
                  Edit
                </Button>
                <Button variant="contained" color="primary" size="small" onClick={() => this.openDialog(user)}>
                  Reset Password
                </Button>
                <Button variant="contained" color="secondary" size="small" onClick={() => this.handleDelete(user)}>
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  render() {
    const { classes, currUser } = this.props;
    const { tab, isLoadingAdmin, isLoadingSupport } = this.state;
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="display2">Users</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" size="large" color="secondary" onClick={() => this.setState({ openAddDialog: true })}>
              Add New User
            </Button>
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs value={tab} onChange={this.handleTabChange}>
              <Tab label="Support" />
              {isSuper(currUser.userGroup) && <Tab label="Admin" />}
            </Tabs>
          </AppBar>
          {tab === 0 && <TabContainer>{this.renderTable("Supports", isLoadingSupport)}</TabContainer>}
          {tab === 1 && isSuper(currUser.userGroup) && <TabContainer>{this.renderTable("Admins", isLoadingAdmin)}</TabContainer>}
        </Paper>
        {this.renderResetDialog()}
        {this.renderAddDialog()}
        {this.renderEditDialog()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UserSupport);
