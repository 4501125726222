import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  transferDialog: {
    height: "90%",
  },
  voidTransferBtnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    "& button": {
      margin: "10px",
    },
  },
  paper: {
    width: "100%",
    height: "calc(75vh)",
    marginTop: theme.spacing.unit * 3,
    overflow: "scroll",
  },
  table: {
    minWidth: 700,
  },
  loadMoreBtns: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "1rem",
    marginLeft: 0,
  },
  headerBtn: {
    marginRight: "1rem",
    marginBottom: ".5rem",
  },
});

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],

      isLoading: true,
      lastEvaluatedKey: null,
      isCreateCouponFormOpen: false,
      createdAtStart: "",
      createdAtEnd: "",
      recipientEmail: "",
      isActive: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.getCoupons = this.getCoupons.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.getCoupons("overwrite", 10, null);
  }

  parseDateString(dateInput) {
    const parts = dateInput.split("-");
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset);
    const newDate = new Date(withoutTimezone).valueOf();
    return newDate;
  }

  buildQueryParams(limit = null, lastEvaluatedKey = null) {
    const { isActive, createdAtStart, createdAtEnd, recipientEmail, note } =
      this.state;
    var params = "";
    if (createdAtStart) {
      // convert createdAtStart from string in format "YYYY-MM-DD" to timestamp
      const createdAtStartTimestamp = this.parseDateString(createdAtStart);
      params += `&createdAtStart=${createdAtStartTimestamp}`;
    }
    if (createdAtEnd) {
      // convert createdAtEnd from string in format "YYYY-MM-DD" to timestamp
      const createdAtEndTimestamp = this.parseDateString(createdAtEnd);
      params += `&createdAtEnd=${createdAtEndTimestamp}`;
    }
    if (recipientEmail) {
      params += `&recipientEmail=${recipientEmail}`;
    }
    if (note) {
      params += `&note=${note}`;
    }
    if (isActive) {
      params += `&isActive=${isActive}`;
    }
    if (limit) {
      params += `&limit=${limit}`;
    }
    if (lastEvaluatedKey) {
      params += `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}`;
    }

    return params.substring(1);
  }

  async getCoupons(action = "overwrite", limit = 10, lastEvaluatedKey = null) {
    if (action === "append" && !lastEvaluatedKey) {
      alert("There are no more coupons to load");
      return;
    }
    const params = this.buildQueryParams(limit, lastEvaluatedKey);
    try {
      this.setState({ isLoading: true });
      const { coupons, lastEvaluatedKey } = await API.get(
        "v2",
        `marketplace/coupons?${params}`
      );

      this.setState({ isLoading: false });
      if (action === "overwrite") {
        this.setState({
          coupons: _.orderBy(coupons, ["createdAt"], ["desc"]),
          lastEvaluatedKey,
        });
      } else if (action === "append") {
        this.setState({
          coupons: _.orderBy(
            [...this.state.coupons, ...coupons],
            ["createdAt"],
            ["desc"]
          ),
          lastEvaluatedKey,
        });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  renderCoupons() {
    const { classes } = this.props;

    if (this.state.coupons && this.state.coupons.length === 0) {
      return (
        <Typography
          align="center"
          color="textSecondary"
          variant="headline"
          style={{ marginTop: "5rem" }}
        >
          {this.state.isLoading ? "Loading..." : "No coupons for this date."}
        </Typography>
      );
    }

    return (
      this.state.coupons &&
      this.state.coupons.length > 0 && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                ID
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Value
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Remaining Value
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Active
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Date
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Name
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Email
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Note
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.coupons.map((coupon) => {
              return (
                <TableRow key={coupon.id}>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {coupon.id}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                    numeric
                  >
                    ${coupon.value.toFixed(2)}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                    numeric
                  >
                    ${coupon.value.toFixed(2) - coupon.valueRedeemed.toFixed(2)}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {coupon.isActive ? "Active" : "Inactive"}
                  </TableCell>

                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {moment.tz(Number(coupon.createdAt), "America/Edmonton").format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    component="th"
                    scope="row"
                  >
                    {coupon.name}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {coupon.email}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {coupon.note}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        this.props.history.push(`/coupons/${coupon.id}`)
                      }
                    >
                      View/Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )
    );
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderForm() {
    const {
      createdAtStart,
      createdAtEnd,
      isActive,
      recipientEmail,
      note
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <TextField
          id="createdAtStart"
          label="Created Start Date"
          type="date"
          name="createdAtStart"
          value={createdAtStart}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.isLoading}
          onChange={this.handleChange}
          style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
        />
        <TextField
          id="createdAtEnd"
          label="Created End Date"
          type="date"
          name="createdAtEnd"
          value={createdAtEnd}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.isLoading}
          onChange={this.handleChange}
          style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
        />

        <TextField
          id="recipientEmail"
          label="Recipient Email"
          type="text"
          name="recipientEmail"
          value={recipientEmail}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.isLoading}
          onChange={this.handleChange}
          style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
        />
        <TextField
          id="note"
          label="Note"
          type="text"
          name="note"
          value={note}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.isLoading}
          onChange={this.handleChange}
          style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
        />
        <FormControl
          style={{
            minWidth: "100px",
            marginRight: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          <InputLabel htmlFor="isActive-selector">Is Active?</InputLabel>
          <Select
            inputProps={{
              name: 'isActive',
              id: 'isActive-selector',
            }}
            value={isActive}
            label="isActive"
            onChange={this.handleChange}
          >
            <MenuItem value={""}></MenuItem>
            <MenuItem value={"true"}>True</MenuItem>
            <MenuItem value={"false"}>False</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => this.getCoupons("overwrite", 10, null)}
          disabled={this.state.isLoading}
          style={{
            marginTop: "0.75rem",
            marginRight: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          Search
        </Button>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="Coupons">
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="display2" style={{ marginBottom: "2rem" }}>
              Coupons
            </Typography>
            {this.renderForm()}
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.headerBtn}
              onClick={() => this.props.history.push("/new-coupon")}
            >
              Create Coupon
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderCoupons()}
            <div className={classes.loadMoreBtns}>
              <Button
                onClick={(e) =>
                  this.getCoupons("append", 50, this.state.lastEvaluatedKey)
                }
                disabled={this.state.isLoading}
                variant="contained"
                color="secondary"
                className={classes.loadMoreBtn}
              >
                Load 50 More
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Coupons);
