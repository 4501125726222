import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, FormControl, Grid, Input, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { API } from "aws-amplify";
import CDialog from '../components/CDialog';
import TextField from '@material-ui/core/TextField';
import { isEmpty, omitBy } from 'lodash';
import moment from "moment-timezone";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  loadMoreBtns: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "1rem",
    marginLeft: 0,
  }
});

class Activities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      lastEvaluatedKey: null,
      isLoading: false,
      isLoadingMore: false,
      filter: {
        userGroup: "",
        activityType: "",
        email: ""
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.search = this.search.bind(this);
  }

  loadData = (params = {}) => {
    const queryString = !isEmpty(params) ? `&${new URLSearchParams(params).toString()}` : "";
    this.setState({ isLoading: true });
    API.get("v2", `marketplace/activities/by/recent?limit=20${queryString}`)
      .then(response => {
        this.setState({
          activities: response.activities,
          lastEvaluatedKey: response.last_evaluated_key,
          isLoading: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isLoading: false });
      });
  }

  async loadMore() {
    const { activities, lastEvaluatedKey, filter } = this.state;
    const params = omitBy(filter, v => v === "");
    if (lastEvaluatedKey) {
      params["lastEvaluatedKey"] = JSON.stringify(lastEvaluatedKey);
    }
    const queryString = !isEmpty(params) ? `&${new URLSearchParams(params).toString()}` : "";
    this.setState({ isLoadingMore: true });
    API.get("v2", `marketplace/activities/by/recent?limit=20${queryString}`)
      .then(response => {
        this.setState({
          activities: [...activities, ...response.activities],
          lastEvaluatedKey: response.last_evaluated_key,
          isLoadingMore: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isLoadingMore: false });
      })
  }


  componentDidMount() {
    this.loadData();
  }

  handleChange(event) {
    this.setState({
      filter: {
        ...this.state.filter,
        [event.target.name]: event.target.value
      },
    });
  }

  async search(e) {
    e.preventDefault();
    const params = omitBy(this.state.filter, v => v === "");
    this.loadData(params);
  }

  getActivityTypes() {
    return [
      "Ticket Purchase",
      "Ticket Purchase Declined",
      "Ticket Listing",
      "Ticket Edit",
      "Ticket Archiving",
      "Profile Edit",
      "Profile Reset Password",
      "Sell Request Approval",
      "Sell Request Rejection",
      "AUTO-APPROVAL",
      "MANUAL-APPROVAL",
      "Bulk SMS",
      "Sales",
      "Coupon",
      "Emergency Mode",
      "User Support",
      "Account Edit",
      "Account Password Reset",
      "Password Reset Link",
      "Blocked User",
      "Game",
      "Seller Specific Page",
      "3DS Status",
      "Gift",
      "Price Change Alert",
      "Sell Request SMS"
    ]
  }

  renderData() {
    const { activities } = this.state;
    if (activities.length > 0) {
      return (
        activities.map((activity, index) => (
          <TableRow key={index}>
            <TableCell>
              {activity.email}
            </TableCell>
            <TableCell>
              {activity.activityType}
            </TableCell>
            <TableCell>
              {activity.description}
            </TableCell>
            <TableCell>
              {moment
                .tz(Number(activity.createdAt), "America/Edmonton")
                .format("MMM DD, YYYY hh:mm A")}
            </TableCell>
          </TableRow>
        ))
      )
    } else {
      return (
        <TableRow>
          <TableCell align="center" colSpan="4">
            <Typography variant="subheading" style={{ 'textAlign': 'center' }}>There is no data</Typography>
          </TableCell>
        </TableRow>
      )
    }
  }

  render() {
    const { classes } = this.props;
    const { filter, lastEvaluatedKey, isLoading, isLoadingMore } = this.state;
    return (
      <React.Fragment>
        <Typography variant="display2">Activities</Typography>
        <Grid container>
          <Grid item sm={12}>
            <Grid container justify="space-between" alignItems="center" spacing={24}>
              <Grid item>
                <form autoComplete="off" onSubmit={this.search}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="user-group">
                          Group
                        </InputLabel>
                        <Select
                          value={filter.userGroup}
                          onChange={this.handleChange}
                          input={<Input name="userGroup" id="user-group" />}
                          displayEmpty
                          name="userGroup"
                          className={classes.selectEmpty}
                          disabled={isLoading}
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="buyer">Buyer</MenuItem>
                          <MenuItem value="seller">Seller</MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="activity-type">
                          Activity Type
                        </InputLabel>
                        <Select
                          value={filter.activityType}
                          onChange={this.handleChange}
                          input={<Input name="activityType" id="activity-type" />}
                          displayEmpty
                          name="activityType"
                          className={classes.selectEmpty}
                          disabled={isLoading}
                        >
                          <MenuItem value="">All</MenuItem>
                          {this.getActivityTypes().map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>                    
                    <Grid item>
                      <TextField
                        id="email"
                        label="Email"
                        name="email"
                        value={filter.email}
                        disabled={isLoading}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" disabled={isLoading || isLoadingMore}>
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">User</TableCell>
                    <TableCell component="th">Type</TableCell>
                    <TableCell component="th">Description</TableCell>
                    <TableCell component="th">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.isLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan="4">
                        <Typography variant="subheading" style={{ 'textAlign': 'center' }}>Loading...</Typography>
                      </TableCell>
                    </TableRow>
                  ) : this.renderData()}
                </TableBody>
              </Table>
              {lastEvaluatedKey && !isLoading && (
                <div className={classes.loadMoreBtns}>
                  <Button
                    onClick={() => this.loadMore()}
                    disabled={isLoadingMore}
                    variant="contained"
                    color="secondary"
                    className={classes.loadMoreBtn}
                  >
                    {isLoadingMore ? "Loading..." : "Load 20 More"}
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Activities);
