import React, { Component } from "react";
import { API } from "aws-amplify";
import { groupBy, isEmpty, isNil, sumBy, take } from "lodash";
import moment from "moment-timezone";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Select,
  MenuItem
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CDialog from "../components/CDialog";
import { getTeamAbbreviation, getTeamName, getZoneLabel } from "../libs/shared/helpers";
import OrderNotes from "../components/orders/OrderNotes";
import TEAMS from "../constants/Teams";

const styles = theme => ({
  table: {
    minWidth: 600,
  },
  textRight: {
    textAlign: "right"
  },
  textCenter: {
    textAlign: "center"
  },
  month: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  paper: {
    marginBottom: 50,
    width: '100%',
    overflowX: 'auto'
  },
});

class UnpaidGames extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBy: "top-50",
      games: null,
      unpaidGames: null,
      month: "",
      isLoading: false,
      copyText: "",
      errors: []
    };

    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  copyValue = (event) => {
    const copyText = event.target.innerHTML;
    navigator.clipboard.writeText(copyText);
    this.setState({
      copyText: `Copied ${copyText} to clipboard!`,
    });
  };

  handleCopyTooltip = (attribute) => {
    this.setState({
      ...this.state,
      copyText: `Copy ${attribute} to clipboard?`,
    });
  };

  isFethingOrders() {
    const fetchingOrders = this.state.games.filter(game => game.loading);
    return fetchingOrders.length > 0;
  }

  fetchOrdersByGameId(eventId) {
    API.get(
      "v2",
      `marketplace/orders/unpaid-by-eventId/${eventId}`
    ).then(response => {
      const newGames = this.state.games.map(game => {
        if (game.id === eventId) {
          return {
            ...game,
            loading: false,
            orders: response.orders,
            unpaid_amount: sumBy(response.orders, (order) => order.ticketPrice * order.noOfSeats)
          };
        }
        return game;
      });
      this.setState({ games: newGames });
    }).catch(error => {
      const newGames = this.state.games.map(game => {
        if (game.id === eventId) {
          return {
            ...game,
            loading: false,
            error: true
          };
        }
        return game;
      });
      this.setState({ games: newGames });
    });
  }

  async fetchOrdersbyTeamSlugs() {
    this.setState({ games: [], errors: [] });
    const teams = TEAMS.filter(team => !['calgary-flames', 'edmonton-oilers', 'winnipeg-jets'].includes(team.homeTeamSlug));
    await Promise.all(
      teams.map(async team => {
        await API.get(
          "v2",
          `marketplace/orders/unpaid-by-team-slug/${team.homeTeamSlug}`
        ).then(response => {
          const newGames = response.events.map(event => {
            return {
              ...event,
              loading: false,
              unpaid_amount: sumBy(event.orders, (order) => order.ticketPrice * order.noOfSeats)
            }
          });
          this.setState(prevState => ({
            games: [ 
              ...prevState.games,
              ...newGames
            ]
          }));
        }).catch(error => {
          this.setState(prevState => ({
            errors: [...prevState.errors, team.homeTeamSlug]
          }));
        });
      })
    )
  }

  async fetchAllUnpaidOrders() {
    const response = await API.get(
      "v2",
      `marketplace/orders/search?isPaid=false&limit=75`
    );
    const excludeTEOrders = response.orders.filter(order => order.seat.sellerEmail !== "te@fansfirst.ca");
    const limitOrders = take(excludeTEOrders, 50);
    const ordersByGame = groupBy(limitOrders, 'game.id');
    const newGames = [];
    for (const gameId in ordersByGame) {
      const game = ordersByGame[gameId][0].game;
      newGames.push({
        ...game,
        loading: false,
        orders: ordersByGame[gameId],
        unpaid_amount: sumBy(ordersByGame[gameId], (order) => order.ticketPrice * order.noOfSeats)
      });
    }
    this.setState({ games: newGames });
  }

  getOrders() {
    const { games } = this.state;
    games.forEach(game => {
      this.fetchOrdersByGameId(game.id);
    });
  }

  getGames(teamSlug) {
    const { filterBy, month } = this.state;
    if (filterBy === "upcoming") {
      const startDate = new Date().getTime();
      return API.get("v2", `marketplace/events/by/dates?start_date=${startDate}`);
    } if (filterBy === "all") {
      const startDate = new Date("2024-01-01").valueOf();
      return API.get("v2", `marketplace/events/by/dates?start_date=${startDate}&team_slug=${teamSlug}`);
    } else if (filterBy === "month") {
      return API.get("v2", `marketplace/events/by/month/${month}`);
    } else {
      return { events: [] }
    }
  }

  handleChangeFilter(e) {
    const filterBy = e.target.value;
    this.setState({ filterBy });
  }

  handleChangeMonth(e) {
    const month = e.target.value;
    this.setState({ month });
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      await this.fetchAllUnpaidOrders();
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      CDialog.error("Error", "Failed to load the data");
    }
  }

  async onSubmit(e) {
    e.preventDefault();

    const { filterBy, month } = this.state;
    if (isEmpty(filterBy)) {
      alert("Please select the filter type first");
      return;
    }

    if (filterBy === "month" && isEmpty(month)) {
      alert("Please select the month first");
      return;
    }

    this.setState({ isLoading: true, errors: [] });

    try {
      if (filterBy === "top-50") {
        await this.fetchAllUnpaidOrders();
        this.setState({ isLoading: false });
      } else {
        let events = [];
        if (filterBy === "all") {
          await Promise.all(
            ['calgary-flames', 'edmonton-oilers', 'winnipeg-jets'].map(async teamSlug => {
              const response = await this.getGames(teamSlug);
              events = [...events, ...response.events];
            })
          );
          await this.fetchOrdersbyTeamSlugs();
          this.setState({ isLoading: false });
        } else {
          const response = await this.getGames();
          events = response.events;
        }
        const url = window.location.href;
        let games = [];
        if (url.includes('development') || url.includes('localhost')) {
          games = events.filter(event => event.hasUnpaidOrders);
        } else {
          games = events.filter(event => !event.testGame && event.hasUnpaidOrders);
        }
  
        this.setState({
          isLoading: false,
          games: games.map(game => ({ ...game, loading: true, orders: [], unpaid_amount: null, error: false }))
        });
  
        this.getOrders();
      }
    } catch (error) {
      this.setState({ isLoading: false });
      CDialog.error("Error", "Failed to load the data");
    }
  }

  getGameDate(game) {
    const { date, timezone, isTbd } = game;
    const regularGameDate = moment
      .tz(date, timezone)
      .format("MMM DD, YYYY h:mm A");
    return isTbd ? "TBD" : regularGameDate;
  }

  getGameName(game) {
    return `${game.opponent} at ${getTeamName(game.homeTeamSlug)}`;
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit} style={{ marginBottom: 40 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={16}
        >
          <Grid item>
            <Typography variant="body1">Filter by</Typography>
          </Grid>
          <Grid item>
            <Select
              value={this.state.filterBy}
              onChange={this.handleChangeFilter}
              inputProps={{
                name: 'filterBy',
                id: 'filterBy',
              }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={"top-50"}>Top 50</MenuItem>
              <MenuItem value={"upcoming"}>Upcoming Games</MenuItem>
              {/* <MenuItem value={"all"}>All Games</MenuItem> */}
              <MenuItem value={"month"}>Game Month</MenuItem>
            </Select>
          </Grid>
          {["top-50", "upcoming", "all"].includes(this.state.filterBy) && (
            <Grid item>
              <Button type="submit" color="primary" variant="contained" size="medium" disabled={this.state.isLoading || isEmpty(this.state.filterBy)}>
                {this.state.isLoading ? 'Searching' : 'Search'}
              </Button>
            </Grid>
          )}
        </Grid>
        {this.state.filterBy === "month" && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={16}
          >
            <Grid item>
              <Typography variant="body1">Select Game Month</Typography>
            </Grid>
            <Grid item>
              <TextField
                name="month"
                value={this.state.month}
                placeholder="Month"
                type="month"
                onChange={this.handleChangeMonth}
              ></TextField>
            </Grid>
            <Grid item>
              <Button type="submit" color="primary" variant="contained" size="medium" disabled={this.state.isLoading || isEmpty(this.state.month)}>
                {this.state.isLoading ? 'Searching' : 'Search'}
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    );
  }

  renderOrders(game, orders) {
    return (
      <Table padding="checkbox">
        <TableHead>
          <TableRow>
            <TableCell>Order Number</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Game</TableCell>
            <TableCell>Game Date</TableCell>
            <TableCell>Seat</TableCell>
            <TableCell>No of Seats</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Seller Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(order => (
            <TableRow key={order.id}>
              <TableCell>
                <Tooltip
                  disableFocusListener
                  title={this.state.copyText}
                  onOpen={() => this.handleCopyTooltip("order number")}
                >
                  <span
                    id={`${order.id}-${order.trackingNumber}`}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.copyValue(e)}
                  >
                    {order.trackingNumber ? order.trackingNumber : "-"}
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell>
                {order.notes ? <OrderNotes notes={order.notes} /> : "-"}
              </TableCell>
              <TableCell>{getTeamAbbreviation(game.homeTeamSlug)}</TableCell>
              <TableCell>
                {moment.tz(Number(order.createdAt), "America/Edmonton").format("MMM DD,YYYY")}
              </TableCell>
              <TableCell>{order.name}</TableCell>
              <TableCell>
                <Tooltip
                  disableFocusListener
                  title={this.state.copyText}
                  onOpen={() => this.handleCopyTooltip("email")}
                >
                  <span
                    id={order.id}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.copyValue(e)}
                  >
                    {String(order.email).trim()}
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell>
                {this.getGameName(game)}
              </TableCell>
              <TableCell>
                {this.getGameDate(game)}
              </TableCell>
              <TableCell>
                {getZoneLabel(order.seat)}
                {" "}
                Row {order.seat.row} {order.isAisleSeat ? "Aisle" : ""}{" "}
                {order.isInstantDelivery ? (
                  <strong style={{ color: "#ff1744" }}>Insta</strong>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell>{order.noOfSeats}</TableCell>
              <TableCell>CAD$ {(order.ticketPrice * order.noOfSeats).toFixed(2)}</TableCell>
              <TableCell>
                <Tooltip
                  disableFocusListener
                  title={this.state.copyText}
                  onOpen={() => this.handleCopyTooltip("email")}
                >
                  <span
                    id={order.id}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.copyValue(e)}
                  >
                    {order.seat.sellerEmail && order.seat.sellerEmail !== "-"
                      ? String(order.seat.sellerEmail).trim()
                      : "-"}
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  renderAmount(game) {
    if (game.error) {
      return <Typography color="secondary">Failed to fetch data</Typography>
    }
    return <Typography>CAD$ {game.unpaid_amount ? game.unpaid_amount.toFixed(2) : "-"}</Typography>
  }

  renderTable(games) {
    const { classes } = this.props;
    const { isLoading, errors } = this.state;

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Game</TableCell>
            <TableCell className={classes.textRight}>Unpaid Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games && games.length > 0 ? (
            <React.Fragment>
              {games.map(game => (
                <TableRow key={game.id}>
                  <TableCell colSpan="2" padding="none">
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            spacing={32}
                          >
                            <Grid item>
                              <Typography>{game.longName} - {this.getGameDate(game)}</Typography>
                            </Grid>
                            <Grid item>
                              {game.loading ? (
                                <Typography>Loading...</Typography>
                              ) : this.renderAmount(game)}
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          {game.loading ? (
                            <Typography>Loading...</Typography>
                          ) : this.renderOrders(game, game.orders)}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                  </TableCell>
                </TableRow>
              ))}
              {errors.map(teamSlug => (
                <TableRow>
                  <TableCell colSpan="2">
                    <Typography color="secondary">Failed to fetch {getTeamName(teamSlug)} games</Typography>
                  </TableCell>
                </TableRow>
              ))}
              {isLoading && (
                <TableRow>
                  <TableCell align="center" colSpan="2">
                    <Typography variant="subheading">
                      Loading...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.textRight}>
                  <b>Total</b>
                </TableCell>
                <TableCell className={classes.textRight}>
                  {(this.isFethingOrders() || isLoading) ? (
                    <Typography>Loading...</Typography>
                  ) : (
                    <b>CAD$ {sumBy(games, "unpaid_amount").toFixed(2)}</b>
                  )}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="2">
                <Typography variant="subheading" className={classes.textCenter}>
                  {isLoading ? "Loading..." : "There is no data"}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  renderMonths() {
    const { classes } = this.props;
    const { games} = this.state;
    const gamesByMonths = groupBy(games, (game) => moment.tz(game.date, game.timezone).format("YYYY-MM"));

    if (isEmpty(gamesByMonths)) {
      return <Typography variant="subheading" style={{ 'textAlign': 'center' }}>There is no data</Typography>
    }

    return (
      <>
        {Object.keys(gamesByMonths).sort().map(month => (
          <Paper key={month} className={classes.paper}>
            <Typography className={classes.month} variant="headline" gutterBottom>
              {moment(month, "YYYY-MM").format("MMMM YYYY")}
            </Typography>
            {this.renderTable(gamesByMonths[month])}
          </Paper>
        ))}
      </>
    );
  }

  render() {
    const { games, filterBy } = this.state;

    return (
      <div>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          spacing={32}
        >
          <Grid item>
            <Typography variant="display2">Unpaid Games</Typography>
          </Grid>
          <Grid item>
            {this.renderForm()}
          </Grid>
        </Grid>
        {!isNil(games) && (
          filterBy === "top-50" ? this.renderTable(games) : this.renderMonths()
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UnpaidGames);
