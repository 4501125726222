import React, { Component } from "react";
import { API } from "aws-amplify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { InputAdornment } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
} from "@material-ui/core";

const styles = {
  root: {
    flexGrow: 1,
  },
  FormControl: {
    marginTop: "1rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
};

class EditCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentWillMount() {
    const coupon = await API.get(
      "v2",
      `marketplace/coupons/${this.props.match.params.id}?adminView=true`
    );
    const { id, email, value, note, name, isActive } = coupon;
    this.setState({
      originalCoupon: coupon,
      id,
      email,
      value,
      note,
      name,
      isActive,
    });
  }

  handleChange(e) {
    if (e.target.name === "isActive") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({
        [e.currentTarget.name]: e.target.value,
      });
    }
  }

  async submit() {
    const { currUser} = this.props;
    this.setState({ isSubmitting: true });

    await API.post("v2", `marketplace/coupons/update`, {
      headers: { email: currUser.email },
      body: {
        id: this.state.id,
        value: this.state.value,
        note: this.state.note,
        isActive: this.state.isActive,
      },
    });
    this.setState({ isSubmitting: false });
    this.props.history.push("/coupons");

    try {
    } catch (e) {
      this.setState({ isSubmitting: false });
      alert(e);
    }
  }

  render() {
    const { classes } = this.props;
    const { email, value, name, note, isActive } = this.state;

    return (
      <div className={classes.root} id="EditCoupon">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="display2">Update Coupon</Typography>

            <form className={classes.form}>
              <Typography className={classes.FormControl}>{email}</Typography>
              <Typography className={classes.FormControl}>{name}</Typography>

              <FormControl className={classes.FormControl}>
                <TextField
                  name="value"
                  value={value}
                  type="currency"
                  label="Value"
                  min="1"
                  max="9999999"
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl className={classes.FormControl}>
                <TextField
                  name="note"
                  value={note}
                  type="text"
                  label="Note"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControlLabel
                style={{ height: "40px", marginTop: "10px" }}
                control={
                  <Checkbox
                    key={Math.random()}
                    name="isActive"
                    textAlign="center"
                    onChange={this.handleChange}
                    defaultChecked={isActive}
                    color="rgb(33, 150, 243)"
                    height="40px"
                  ></Checkbox>
                }
                label={
                  <Typography
                    style={{
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      margin: "0px",
                      fontSize: "1rem",
                    }}
                    className={classes.FormControl}
                  >
                    Is Active
                  </Typography>
                }
              />
              <FormControl className={classes.FormControl}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={this.state.isSubmitting}
                  onClick={this.submit}
                >
                  Update Coupon
                </Button>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(EditCoupon);
