import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { API } from 'aws-amplify';
import CDialog from '../CDialog';

class RefundDalog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      note: ""
    };
  }

  async refundOrder(note) {
    const { order, currUser, onSuccess } = this.props;

    try {
      this.setState({ isSubmitting: true, note });
      await API.post("v2", `marketplace/orders/refund?orderId=${order.id}`, {
        headers: { email: currUser.email },
        body: { note }
      });
      this.setState({ isSubmitting: false });
      onSuccess(order.id, note);
    } catch (error) {
      this.setState({ isSubmitting: false });
      CDialog.error("Error!", "Failed to refund the order.");
    }
  }

  render() {
    const { isOpen, order, handleClose } = this.props;
    const { isSubmitting, note } = this.state;

    return (
      <Dialog
        fullWidth={true}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Refund Order</DialogTitle>
        <DialogContent>
          {order && <Typography gutterBottom>{order.name}</Typography>}
          {order && <Typography gutterBottom>{order.email}</Typography>}
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            {order && order.description}
          </Typography>
          <div>
            <Button variant="outlined" disabled={isSubmitting} color="secondary" style={{ marginRight: "1rem" }} onClick={() => this.refundOrder("REFUNDED")}>
              REFUND {isSubmitting && note === "REFUNDED" && <CircularProgress color="secondary" size={20} style={{ marginLeft: 5 }} />}
            </Button>
            <Button variant="outlined" disabled={isSubmitting} color="secondary" onClick={() => this.refundOrder("VOIDED")}>
              VOID {isSubmitting && note === "VOIDED" && <CircularProgress color="secondary" size={20} style={{ marginLeft: 5 }} />}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.isSubmitting}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RefundDalog;
