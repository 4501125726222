import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { chain, sortBy, get, has, isEmpty, isNil, filter, find } from 'lodash';
import querySearch from "stringquery";
import {
  Paper,
  Grid,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
  Button,
  Select,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputLabel,
} from "@material-ui/core";
import validator from "validator";
import TEAMS from '../constants/Teams';
import { getSeatLabel, renderZoneName, renderRowName, testDataCheck, renderSeatNumbers } from "../libs/shared/helpers";
import { isAllowedAction } from "../libs/rbac";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    width: 50,
  },
  teamName: {
    textAlign: "center",
    cursor: "pointer",
  },
  seatStatuses: {
    padding: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    buttons: {
      marginTop: "1rem",
      position: "sticky",
      bottom: "0rem",
      backgroundColor: "#fff",
      width: "100%",
      padding: "1rem 0",
    },
    button: {
      width: "calc(50% - 1rem)",
      margin: "0.5rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    buttons: {
      marginTop: "1rem",
      position: "sticky",
      bottom: "0rem",
      backgroundColor: "#fff",
      width: "100%",
      padding: "1rem 0",
    },
    button: {
      width: "calc(50% - 1rem)",
      margin: "0.5rem",
    },
  },
  seats: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  seatItem: {
    padding: "1rem",
    borderBottom: "1px solid #f8f8f8",
    cursor: "pointer",
    transition: ".25s all ease-in-out",
    "&:hover": {
      backgroundColor: "#eaeaea",
      transition: ".25s all ease-in-out",
    },
  },
  selectedSeatItem: {
    backgroundColor: "#eaeaea",
    padding: "1rem",
    borderBottom: "1px solid #f8f8f8",
    cursor: "pointer",
    transition: ".25s all ease-in-out",
  },
  seatItemPrice: {
    color: "#4caf50 !important",
  },
  gameDetails: {
    marginBottom: ".5rem",
  },
  seatDetails: {
    marginBottom: ".5rem",
  },
  form: {
    marginTop: ".5rem",
  },
  formControl: {
    minWidth: 320,
  },
});

const optionBuyers = [
  {
    id: 1,
    name: 'Tix Error Fix',
    email: 'tix@fansfirst.ca',
    mobileNo: '+14037682298'
  },
  {
    id: 2,
    name: 'David Ricciardi',
    email: 'ricciardi.dave@gmail.com',
    mobileNo: '+14039784894'
  },
  {
    id: 3,
    name: 'Chad Douglas',
    email: 'wickter333@gmail.com',
    mobileNo: '+15033271037'
  }
]

class BuyListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: null,
      seats: null,
      isLoading: true,
      selectedGame: "",
      selectedTeam: null,
      status: "1", // 1 for active and 2 for sold
      confirmationOpened: false,
      selectedListing: {},
      isLoadingForm: false,
      paymentRefNo: "",
      notes: "",
      succeeded: false,
      buyer: {
        name: "",
        email: "",
        mobileNo: "",
      },
      isSubmitted: false
    };

    this.handleGameChange = this.handleGameChange.bind(this);
    this.onTeamSelection = this.onTeamSelection.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.onBuyerSelection = this.onBuyerSelection.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  async componentWillMount() {
    let { game, team } = querySearch(this.props.location.search);

    let allGames = await this.games();
    let currentTime = new Date().getTime();
    const tbdGames = chain(allGames)
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return !doesNeedTestData && game.isTbd && !game.isArchived;
      })
      .sortBy("playoffSequence", "asc");
    const regularGames = chain(allGames)
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return (
          moment.tz(game.date, game.timezone).add(120, "minutes").valueOf() >=
          moment.tz(currentTime, game.timezone).valueOf() &&
          !doesNeedTestData &&
          !game.isTbd &&
          !game.isArchived
        );
      })
      .sortBy("date", "asc")
      .value();
    const games = [...regularGames, ...tbdGames];
    const nextUpcomingGame = games.find((game) => game.homeTeamSlug === "calgary-flames");

    const selectedGame = nextUpcomingGame ? nextUpcomingGame : games[0];

    team = team ? team : selectedGame.homeTeamSlug;
    game = game ? game : selectedGame.id;

    let filteredGames = null;
    let sortedSeats = null;

    if (game && team) {
      filteredGames = games.filter((game) => {
        if (game.homeTeamSlug === team) {
          return game;
        }

        return null;
      });

      const seats = await this.seats(game);
      sortedSeats = sortBy(seats, ["zoneNo", "zone", "row"]);
    }

    this.setState({
      isLoading: false,
      games,
      filteredGames,
      seats: sortedSeats,
      filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
      selectedTeam: team,
      selectedGame: game,
    });
  }

  seats = (game) => {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?include_seller_details=true&include_inactive=true`
    )
      .then((data) => {
        this.setState({ ...this.state, isLoading: false });
        return data;
      })
      .catch((e) => {
        alert("something wrong", JSON.stringify(e));
      });
  };

  seat(seat, game) {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?seatId=${seat}&include_seller_details=true`
    );
  }

  games() {
    return API.get("v2", `marketplace/events/all?includePrevious=true`);
  }

  game(id) {
    return API.get("v2", `marketplace/events/by/eventId/${id}`);
  }

  sortSeatsBy = (seats, sortBy) => {
    switch (sortBy) {
      case "section": {
        return sortBy(seats, ["zoneNo", "zone", "row"]);
      }
      case "price": {
        return sortBy(seats, ["price", "zoneNo", "zone", "row"]);
      }
      case "email": {
        return sortBy(seats, ["sellerEmail", "zoneNo", "zone", "row"]);
      }
      default: {
        return seats;
      }
    }
  }

  handleTeamChange(e) {
    const selectedTeam = e.target.value;
    this.onTeamSelection(selectedTeam);

    this.setState({
      selectedTeam,
      selectedGame: "",
    });
  }
  
  async handleGameChange(e) {
    const gameId = e.target.value;
    this.setState({ ...this.state, isLoading: true });
    const seats = await this.seats(gameId);
    const sortedSeats = sortBy(seats, ["zoneNo", "zone", "row"]);

    this.setState({
      selectedGame: gameId,
      seats: sortedSeats,
      status: "1",
      filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
    });
  }
  
  renderTeams() {
    const { 
      games, 
      selectedTeam,
    } = this.state;
    const { classes } = this.props;

    if (!games) {
      return <Typography> Loading team please wait... </Typography>;
    }
    
    return (
        <Grid container className={classes.teamsList}>
          <FormControl
            style={{
            minWidth: "20%",
            marginRight: "1.5rem",
            }}
          >
            <InputLabel htmlFor="team-selector">Select Team</InputLabel>
            <Select
              id="team-selector"
              value={selectedTeam}
              label="team"
              name="teamSelected"
              onChange={this.handleTeamChange}
            >
            {TEAMS.map(team => (
                <MenuItem key={team.homeTeamSlug} value={team.homeTeamSlug}>{team.name}</MenuItem>
            ))}
            </Select>
          </FormControl>
          {selectedTeam && this.renderGames()}
        </Grid>
    );
  }

  renderGames() {
    const { filteredGames, selectedGame, isLoading } = this.state;
    if (!filteredGames) {
      return;
    }

    const menuItems = filteredGames.map((game) => {
      const { id, date, timezone, isTbd } = game;

      const regularGameDate = `${moment
        .tz(date, timezone)
        .format("ddd MMM DD h:mm A")}`;
      const gameDate = isTbd ? "TBD" : regularGameDate;

      return (
        <MenuItem value={id} key={id} selected={id === selectedGame}>
          {game.shortName} - {gameDate}
        </MenuItem>
      );
    });

    return (
      <FormControl
        style={{
          minWidth: "300px",
          marginRight: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        <InputLabel id="game-selector">Select Game</InputLabel>
        <Select
          id="game-selector"
          value={selectedGame}
          label="game"
          onChange={this.handleGameChange}
          style={{ marginRight: "1rem" }}
          disabled={isLoading}
        >
          <MenuItem value={""}></MenuItem>
          {menuItems}
        </Select>
        {isLoading && (
          <FormHelperText>Loading...</FormHelperText>
        )}
      </FormControl>
    );
  }

  sortSeats(by) {
    const { filteredSeats } = this.state;
    let sortedSeats;
    this.setState({
      sortBy: by,
    });

    switch (by) {
      case "section": {
        sortedSeats = sortBy(filteredSeats, ["zoneNo", "zone", "row"]);
        break;
      }
      case "price": {
        sortedSeats = sortBy(filteredSeats, [
          "price",
          "zoneNo",
          "zone",
          "row",
        ]);
        break;
      }
      case "email": {
        sortedSeats = sortBy(filteredSeats, [
          "sellerEmail",
          "zoneNo",
          "zone",
          "row",
        ]);
        break;
      }

      default: {
        sortedSeats = filteredSeats;
      }
    }

    this.setState({
      filteredSeats: sortedSeats,
    });
  }

  renderSeats() {
    const { currUser } = this.props;
    const { userGroup } = currUser;
    const { filteredSeats, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      filteredSeats && (
        <React.Fragment>
          <Paper
            style={{
              marginTop: "2rem",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => this.sortSeats("section")}
                  >
                    Section
                  </TableCell>
                  <TableCell> Row No </TableCell>
                  <TableCell
                    style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => this.sortSeats("price")}
                  >
                    Seat Price
                  </TableCell>
                  <TableCell> Seat Numbers </TableCell>
                  <TableCell> No.of Seats Available </TableCell>
                  <TableCell> Seats Sold </TableCell>
                  <TableCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => this.sortSeats("email")}
                  >
                    Seller Email
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSeats.map((seat, index) => {
                  return (
                    <TableRow key={seat.seatId}>
                      <TableCell>
                        {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}{" "}
                        <Typography
                          style={{ fontWeight: "bold", display: "inline" }}
                        >
                          {seat.isAisleSeat && parseInt(seat.seatsSold) === 0
                            ? "A"
                            : null}
                        </Typography>
                      </TableCell>
                      <TableCell 
                        style={{ textAlign: "right" }}
                      >
                        CAD$ {(seat.price).toFixed(2)}
                      </TableCell>
                      <TableCell>{getSeatLabel(seat)}</TableCell>
                      <TableCell>
                        {seat.noOfSeats - seat.seatsSold}/ {seat.noOfSeats}
                      </TableCell>
                      <TableCell> {seat.seatsSold} </TableCell>
                      <TableCell>
                        {seat.sellerEmail ? seat.sellerEmail : "-"}
                      </TableCell>
                      <TableCell>
                        {isAllowedAction(userGroup) && (
                          <Button
                            href={`/seats/${seat.seatId}?game=${seat.game}`}
                          >
                            View
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                      {isAllowedAction(userGroup) && (
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={false}
                          onClick={() => this.handleConfirmationOpen(seat)}
                        >
                          Buy
                        </Button>
                      )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </React.Fragment>
      )
    );
  }

  onBuyerSelection(event) {
    const buyer = find(optionBuyers, (buyer) => buyer.id === event.target.value);
    this.setState({ buyer: { ...buyer } })
  }

  async submitForm(e) {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const {
      paymentRefNo,
      notes,
      selectedListing,
      selectedGame,
      buyer
    } = this.state;

    if (!isEmpty(this.validateInput(buyer))) {
      return;
    }

    this.setState({
      isLoadingForm: true,
    });

    const section = selectedListing.zone === "Upper Zone" ? selectedListing.zone : "Section";

    const gameDate = `${moment
      .tz(selectedListing.gameDate, selectedListing.gameDetails.timezone)
      .format("ddd, MMM D YYYY")} at ${moment
        .tz(selectedListing.gameDate, selectedListing.gameDetails.timezone)
        .format("h:mm A")}`;

    const description = `${selectedListing.noOfSeats} ticket(s): ${selectedListing.gameDetails.longName} - ${gameDate}, ${section} ${selectedListing.zoneNo} Row ${selectedListing.row}`;
   
    try {
      await API.post("v2", "marketplace/orders/manual-checkout", {
        body: {
          name: buyer.name,
          email: buyer.email,
          mobileNo: buyer.mobileNo,
          paymentRefNo: isEmpty(paymentRefNo) ? null : paymentRefNo,
          notes: isEmpty(notes) ? null : notes,
          description,
          noOfSeats: selectedListing.noOfSeats,
          game: selectedListing.game,
          seat: selectedListing.seatId,
          ticketPrice: selectedListing.price,
          amount: selectedListing.noOfSeats * selectedListing.price,
        },
      });

      const seats = await this.seats(selectedGame);
      const sortedSeats = sortBy(seats, ["zoneNo", "zone", "row"]);
      
      this.setState({
        isLoadingForm: false,
        succeeded: true,
        confirmationOpened: false,
        seats: sortedSeats,
        status: "1",
        filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
      });

      alert("Order created!");
    } catch (e) {
      if (e.response) {
        const { data } = e.response;

        if (!data.isSeatAvailable) {
          alert(data.message);
        }
      } else {
        console.error(e);
        throw e;
      }
    }
  }

  validateInput(input) {
    const { name, email, mobileNo } = input;
    return {
      ...(!isNil(name) && validator.isEmpty(name) && { name: "Name is required" }),
      ...(!isNil(email) && validator.isEmpty(email) && { email: "Email is required" }),
      ...(!isNil(email) && !validator.isEmpty(email) && !validator.isEmail(email) && { email: "Please enter a valid email address" }),
      ...(!isNil(mobileNo) && validator.isEmpty(mobileNo) && { mobileNo: "Phone number is required" }),
      ...(!isNil(mobileNo) && !validator.isEmpty(mobileNo) && !validator.isMobilePhone(mobileNo, 'any', { strictMode: false }) && { mobileNo: "Please enter a valid phone number including country code" }),
    };
  }

  renderForm() {
    const { buyer, isSubmitted, isLoadingForm, succeeded } = this.state;
    const { classes } = this.props;
    const validated = this.validateInput(buyer);

    return (
      <form onSubmit={this.submitForm} className={classes.form}>
        <FormControl className={classes.formControl}>
          <InputLabel id="buyer-selector">Select Buyer</InputLabel>
          <Select
            id="buyer-selector"
            value={this.state.buyer.id || ''}
            label="buyerSelected"
            onChange={this.onBuyerSelection}
          >
            {optionBuyers.map(buyer => (
              <MenuItem value={buyer.id} key={buyer.id} selected={buyer.id === this.state.buyer.id}>
                {buyer.name} 
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          disabled={isLoadingForm}
          id="name"
          label="Name"
          className={classes.textField}
          value={this.state.buyer.name}
          onChange={e => this.setState({ buyer: { ...buyer, name: e.target.value } })}
          error={isSubmitted && has(validated, 'name')}
          helperText={isSubmitted && get(validated, 'name')}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={isLoadingForm}
          id="email"
          label="Email"
          className={classes.textField}
          value={this.state.buyer.email}
          onChange={e => this.setState({ buyer: { ...buyer, email: e.target.value.toLowerCase() } })}
          error={isSubmitted && has(validated, 'email')}
          helperText={isSubmitted && get(validated, 'email')}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={isLoadingForm}
          id="mobileNo"
          label="Mobile No."
          className={classes.textField}
          value={this.state.buyer.mobileNo}
          onChange={e => this.setState({ buyer: { ...buyer, mobileNo: e.target.value } })}
          error={isSubmitted && has(validated, 'mobileNo')}
          helperText={isSubmitted && get(validated, 'mobileNo')}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={isLoadingForm}
          id="paymentRefNo"
          label="Payment Ref#"
          className={classes.textField}
          value={this.state.paymentRefNo}
          onChange={e => this.setState({ paymentRefNo: e.target.value })}
          margin="normal"
          fullWidth={true}
        />

        <TextField
          disabled={isLoadingForm}
          id="notes"
          label="Notes"
          className={classes.textField}
          value={this.state.notes}
          multiline
          rowsMax="4"
          onChange={e => this.setState({ notes: e.target.value })}
          margin="normal"
          fullWidth={true}
        />

        <div className={classes.buttons}>
          <Button
            disabled={this.isLoading}
            className={classes.button}
            color="default"
            variant="contained"
            onClick={this.handleConfirmationClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            disabled={
              isLoadingForm ||
              succeeded
            }
            type="submit"
            onClick={this.submitForm}
          >
            Submit Order
          </Button>
        </div>
      </form>
    );
  }

  handleConfirmationOpen = (listing) => {
    this.setState({
      selectedListing: listing,
      confirmationOpened: true,
      isLoadingForm: false,
      paymentRefNo: "",
      notes: "",
      succeeded: false,
      buyer: {
        name: "",
        email: "",
        mobileNo: "",
      },
      isSubmitted: false
    });
  };

  handleConfirmationClose = () => {
    this.setState({
      selectedListing: {},
      confirmationOpened: false,
    });
  };

  renderConfirmation() {
    const { classes } = this.props;
    const { confirmationOpened, selectedListing } = this.state;
    if (!confirmationOpened) {
      return;
    }
    
    const regularGameDate = `${moment
      .tz(selectedListing.gameDate, selectedListing.gameDetails.timezone)
      .format("ddd, MMM DD YYYY h:mm A")}`;
    const gameDate = selectedListing.gameDetails.isTbd ? "TBD" : regularGameDate;
    
    return (
      <Dialog open={confirmationOpened} onClose={this.handleConfirmationClose} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <DialogTitle>Confirm Purchase</DialogTitle>
        <DialogContent>
          <div className={classes.gameDetails}>
            <Typography variant="body2">{selectedListing.gameDetails.longName}</Typography>
            <Typography variant="body1">
              {gameDate}
            </Typography>
          </div>
          <div className={classes.seatDetails}>
            <Typography variant="body2">
              {selectedListing.zone} {selectedListing.zoneNo} Row {selectedListing.row}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
              {selectedListing.sellerFullName} - {selectedListing.sellerEmail}
            </Typography>
            <Typography variant="caption">Seat Numbers</Typography>
            <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
              {renderSeatNumbers(selectedListing, "-")}
            </Typography>
            <Typography variant="caption">Price</Typography>
            <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
              CAD$ {selectedListing.price.toFixed(2)} ea
            </Typography>
            <Typography variant="caption">Available Seats</Typography>
            <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
              {selectedListing.noOfSeats - selectedListing.seatsSold} / {selectedListing.noOfSeats}
            </Typography>
            <Typography variant="body2">
            CAD$ {(selectedListing.price.toFixed(2) * selectedListing.noOfSeats).toFixed(2)} Total
            </Typography>
          </div>
          {this.renderForm()}
        </DialogContent>
      </Dialog>
    );
  }

  getFilteredSeats(seats, status) {
    return filter(seats, (seat) => {
      if (status === "1" && !seat.isSold && !seat.isArchived) {
        return seat;
      } else if (status === "2" && seat.isSold) {
        return seat;
      } else if (status === "3" && seat.isArchived) {
        return seat;
      }
    });
  }

  async onTeamSelection(slug) {
    this.setState({
      selectedTeam: slug,
      isLoading: true,
    });

    const { games } = this.state;
    if (!games) {
      return null;
    }

    const filtered = games.filter((game) => {
      if (game.homeTeamSlug === slug) {
        return game;
      }

      return null;
    });

    const nextUpcomingGame = games.find((game) => game.homeTeamSlug === slug);

    const selectedGameId = nextUpcomingGame ? nextUpcomingGame.id : games[0].id;

    const seats = await this.seats(selectedGameId);
    const sortedSeats = sortBy(seats, ["zoneNo", "zone", "row"]);

    this.setState({
      filteredGames: filtered,
      isLoading: false,
      selectedGame: selectedGameId,
      seats: sortedSeats,
      status: "1",
      filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="display2">Buy Listing</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "2rem",
            }}
          >
            {this.renderTeams()} 
            {this.renderSeats()}
            {this.renderConfirmation()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(BuyListing);
